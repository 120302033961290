import { getCampaignGeneralInfo, updateCampaignGeneralInfo } from './../../services/campaignv1';
import UtmTrackingInput from '@/components/campaign/utmTracking/utmTrackingInput';
import CampaignSteps from '@/components/campaign/createCampaignSteps';
import CampaignFooter from '@/components/campaign/createCampaignFooter';
const moment = require('moment');

export default {
  name: 'campaignGeneralInfoUpdate',
  props: {},
  components: {
    UtmTrackingInput,
    CampaignSteps,
    CampaignFooter
  },
  data() {
    return {
      step: 0,
      fetchingData: true,

      campaignId: null,
      campaign: null,

      campaignErrors: [],
      savingCampaign: false
    };
  },
  mixins: [],
  watch: {},

  methods: {
    async fetchDetailsForEdit() {
      try {
        this.fetchingData = true;

        // Fetch Details
        let response = await getCampaignGeneralInfo(this.campaignId);
        if (!response.data) {
          this.$router.replace('/404');
          return;
        }

        this.campaign = response.data;

        // Format campaign start time if specified.
        this.campaign.start_time = this.campaign.start_time && this.campaign.start_time != 'N/A' ? moment(parseInt(this.campaign.start_time)).format('YYYY-MM-DD HH:mm:ss') : null;

        // Render utm content if found.
        if (this.campaign.utm_tracking_config) {
          requestAnimationFrame(() => {
            this.$refs.utmInput.render(JSON.parse(this.campaign.utm_tracking_config));
          });
        }

        if (this.campaign.channel == 'webSocialProof') {
          this.campaign.type = 'onAction';
          this.campaign.action_event_list = this.campaign.action_event_list ? JSON.parse(this.campaign.action_event_list) : '';
        }

        this.fetchingData = false;
      } catch (err) {
        this.reportError(err);
        this.errorToast('Failed to read campaigns. Please contact support.');
        this.fetchingData = false;
      }
    },

    async validateForm() {
      return new Promise((resolve) => {
        this.$refs['campaignInfoForm'].validate((valid) => {
          resolve(valid);
        });
      });
    },

    async validateAndSave() {
      this.campaignErrors = [];

      // Validate UTM tracking info
      let isUtmValid = await this.$refs.utmInput.validateForm();
      if (!isUtmValid) {
        this.campaignErrors.push('Please provide valid UTM tracking details.');
      }

      // Validation: scheduled delivery time, if later specified, validate date.
      if (this.campaign.start_type != 'now' && !moment(this.campaign.start_time).isValid()) {
        this.campaignErrors.push('Please specify valid campaign delivery time.');
      }

      // Validate On Action
      if (this.campaign.type == 'onAction') {
        // Validation: event name validation
        if (!this.$refs.campaignOnActionEventFilter || !this.$refs.campaignOnActionEventFilter.getFilters()) {
          this.campaignErrors.push('Please specify on customer action event.');
        }
        // Validation: start time, if later specified, validate date.
        if (this.campaignStartType != 'now' && !moment(this.campaignStartTime).isValid()) {
          this.campaignErrors.push('Please specify valid campaign start time.');
        }
        // Validation: end time, if later specified, validate date.
        if (this.campaignEndType != 'never' && !moment(this.campaignEndTime).isValid()) {
          this.campaignErrors.push('Please specify valid campaign end time.');
        }
      }

      // Validate Form
      let valid = await this.validateForm();
      if (!valid) {
        this.campaignErrors.push('Please specify campaign name.');
      }

      // If all info valid, read content of current and move to next step.
      if (this.campaignErrors.length != 0) {
        return false;
      }

      // Save Campaign.
      return await this.saveChanges();
    },

    async saveChanges() {
      try {
        this.savingCampaign = true;

        let campaignToSave = JSON.parse(JSON.stringify(this.campaign));

        // Check for custom start time if provided.
        if (this.campaign.start_time && this.campaign.start_time != 'N/A') {
          campaignToSave.start_time = moment(this.campaign.start_time).valueOf();
        } else {
          campaignToSave.start_time = moment().valueOf();
        }

        if (this.campaign.type == 'onAction') {
          campaignToSave.action_event_list = JSON.stringify(this.$refs.campaignOnActionEventFilter.getFilters());
        }

        // Put utm Tracking Config
        let utmConfig = this.$refs.utmInput.getContents();
        campaignToSave.utm_tracking_config = utmConfig ? JSON.stringify(utmConfig) : null;

        // Save Changes
        console.log('save params', campaignToSave);

        // Fetch Details
        await updateCampaignGeneralInfo(this.campaignId, campaignToSave);

        this.savingCampaign = false;

        return true;
      } catch (err) {
        this.reportError(err);
        this.errorToast('Failed to read campaigns. Please contact support.');
        this.savingCampaign = false;
      }
    },

    async onGoNext() {
      let result = await this.validateAndSave();
      if (result) {
        // Redirect to targetAudiancePage
        this.savingCampaign = true;
        // Redirect to targetAudiancePage
        this.$router.push({ name: 'campaignTargetAudiance', params: { id: this.campaignId } });
      }
    },

    async onSaveDraft() {
      let isSaved = await this.validateAndSave();
      if (isSaved) {
        this.successToast('Draft Saved.');
      }
    }
  },

  mounted() {
    this.campaignId = this.$route.params.id;
    if (this.campaignId == null) {
      // Redirect to 404
      this.$router.replace('/404');
      return;
    }
    this.fetchDetailsForEdit();
  }
};
