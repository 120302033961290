<template>
  <div id="campaignGeneralInfoPage">
    <div class="loadingDiv" v-if="fetchingData" v-loading="true" style="min-height: 300px"></div>

    <template v-else>
      <!-- Campaign Steps -->
      <CampaignSteps v-if="campaign" :step="step" :campaign="campaign"></CampaignSteps>

      <div class="campaignInfo">
        <!-- Validation Error List -->
        <div class="rounded-md bg-red-600 p-4 mb-3" v-if="campaignErrors.length > 0">
          <div class="flex">
            <div class="flex-shrink-0">
              <!-- Heroicon name: solid/x-circle -->
              <svg class="h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
              </svg>
            </div>
            <div class="ml-2">
              <h3 class="text-sm font-medium text-white">There were 2 errors with your submission</h3>
              <div class="mt-2 text-sm text-white">
                <ul role="list" class="list-disc pl-3 space-y-1">
                  <li :key="index" v-for="(error, index) in campaignErrors">{{ error }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <el-card class="box-card" shadow="never">
          <div slot="header" class="clearfix">
            <span>Basic Campaign Details</span>
          </div>

          <el-form class="campaignName" :model="campaign" ref="campaignInfoForm" label-position="top">
            <!-- Campaign Name -->
            <el-form-item label="Campaign Name" prop="name" :rules="[{ required: true, message: 'Campaign name is required' }]">
              <el-input class="w-72" type="text" v-model="campaign.name" placeholder="Enter campaign name" autocomplete="off"> </el-input>
            </el-form-item>

            <el-divider></el-divider>

            <el-form-item label="When you want to start campaign?">
              <el-radio-group v-model="campaign.start_type">
                <el-radio label="now">Start Immediately</el-radio>
                <el-radio label="later">Start Later</el-radio>
              </el-radio-group>
            </el-form-item>

            <!-- If Start type is later, provide later date -->
            <el-form-item label="Campaign Start Time" v-if="campaign.start_type == 'later'" class="-mt-5">
              <el-date-picker v-model="campaign.start_time" type="datetime" placeholder="Pick a date and time"> </el-date-picker>
            </el-form-item>

            <el-divider></el-divider>

            <div class="relative">
              <a href="http://help.growlytics.in/en/articles/5605313-setting-up-frequency-capping" target="_blank" class="absolute right-0">
                <button class="text-secondary-800 background-transparent uppercase font-bold py-1 text-xs outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">Learn More About Frequency Capping <i class="el-icon-top-right"></i></button>
              </a>
              <el-checkbox v-model="campaign.follow_capping" class="font-medium text-gray-700"> Follow Frequency Capping </el-checkbox>
              <div class="text-gray-400 text-xs pl-4 -mt-1">
                If set, message will not be sent if frequency capping limit reached for that customer.
                <a target="_blank" href="/settings/campaign-settings" class="text-primary-600">Click here</a>
                to check your frequency capping limits.
              </div>
            </div>

            <el-divider></el-divider>

            <UtmTrackingInput :channelType="campaign.channel" ref="utmInput"></UtmTrackingInput>
          </el-form>
        </el-card>
      </div>

      <!-- Step Footer -->
      <CampaignFooter :step="step" v-if="campaign" :campaign="campaign" @onNext="onGoNext" @onSaveDraft="onSaveDraft"></CampaignFooter>
    </template>
  </div>
</template>
<style lang="scss" src="./campaignGeneralInfoUpdate.scss"></style>

<script>
import campaignGeneralInfoPageComponent from './campaignGeneralInfoUpdateComponent';
export default campaignGeneralInfoPageComponent;
</script>
